@tailwind base;
/* @tailwind components;
@tailwind utilities; */

body {
  background-color: #1D2125;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.header_offset {
  padding-top: 48px;
}
