.position {
  display: none;
  position: absolute;
  right: 3px;
  top: 50px;
  width: 304px;
}
.rX4pAv5sWHFNjp {
  color: #b6c2cf;
  /* var(--ds-text, #172b4d); */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  outline: 0;
  background-color: #282e33;
  /* var(--ds-surface-overlay, #ffffff); */
  box-shadow: var(
    --ds-shadow-overlay,
    0px 8px 12px #091e4226,
    0px 0px 1px #091e424f
  );
}
.q2PzD_Dkq1FVX3 {
  -webkit-overflow-scrolling: touch;
  // -webkit-transform: translate3d(0, 0, 0);
  padding: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #ffffff4d #00000026;
  scrollbar-width: auto;
}
.JaxGrNSZxJ4ghJ h2 {
  margin: 16px 0 8px;
  padding: 0 8px;
  color: #8c9bab;
  /* var(--ds-text-subtlest, #626f86); */
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}
.TyNFo3ay3iQKOz {
  display: flex;
  max-width: 100%;
  padding: 8px;
}
.fG5A960F7Q3GJJ {
  margin-right: 8px;
}
.B1uWdim9Jd0dJ9 {
  position: relative;
  overflow: visible;
  line-height: 10px;
  vertical-align: top;
  white-space: nowrap;
}
.kFZ3hS99jGmKWk {
  background-color: transparent;
}
.DweEFaF5owOe02 {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 28px;
  max-width: 128px;
  height: 28px;
  max-height: 128px;
  overflow: hidden;
  border: 0;
  border-radius: 100%;
  opacity: 1;
  background-color: #2121ff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  white-space: nowrap;
  // -webkit-print-color-adjust: exact;
}
.vqeVFoaA8KQnX4 {
  min-width: 0;
}
.lzFtVDCea8Z9jO {
  margin-top: 2px;
}
.Ej7WGzTnvdxL7I {
  overflow: hidden;
  color: #9fadbc;
  /* var(--ds-text-subtle, #44546f); */
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.IfckxJ5PbpJuxT {
  margin: 0 -12px;
}
.IfckxJ5PbpJuxT > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.JaxGrNSZxJ4ghJ li {
  list-style: none;
}
.JaxGrNSZxJ4ghJ a,
.JaxGrNSZxJ4ghJ button {
  box-sizing: border-box;
  padding: 8px 20px;
}
.gJDsPins_eYkBM {
  border: none;
  background-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px 12px;
  transition: none;
  border-radius: 0;
  outline: 0;
  background: var(--ds-background-neutral-subtle, #00000000);
  box-shadow: none;
  color: #b6c2cf;
  /* var(--ds-text, #172b4d); */
  text-align: left;
  text-decoration: none;
}
.gJDsPins_eYkBM:hover {
  border: none;
  background-color: transparent;
  box-shadow: none;
  background: #a1bdd914;
  /* var(--ds-background-neutral-subtle-hovered, #091e420f); */
  color: #b6c2cf;
  /* var(--ds-text, #172b4d); */
  text-decoration: none;
}
.z07_VMyx9kmLq0 > span {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.LCeoUSr_PkZrP2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.z07_VMyx9kmLq0 > span > span {
  align-self: center;
}
.BmRHtH7FIX0jcL {
  display: flex;
  align-items: center;
}
.REGdK7LUxpdgvv,
.rnYaSURE60hdsz {
  flex-shrink: 0;
  max-height: 20px;
  padding: 0 4px;
}
.A3PtEe1rGIm_yL {
  display: inline-block;
  line-height: 1;
}
.css_snhnyn {
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
}
.css_snhnyn > svg {
  overflow: hidden;
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;
  color: var(--icon-primary-color);
  fill: var(--icon-secondary-color);
  vertical-align: bottom;
}
.neoUEAwI0GETBQ svg {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.css_1wits42 {
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  width: 16px;
  height: 16px;
}
.css_1wits42 > svg {
  width: 16px;
  height: 16px;
  overflow: hidden;
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;
  color: var(--icon-primary-color);
  fill: var(--icon-secondary-color);
  vertical-align: bottom;
}
.hDigGK0jR2_0pl {
  list-style: none;
}
.hDigGK0jR2_0pl::after {
  content: ' ';
  display: block;
  height: 1px;
  margin: 8px 10px;
  background-color: #a6c5e229;
  // var(--ds-border, #091e4224);
}
.uzXECJrSLNu_bU {
  margin-left: -4px;
}
// input,
// textarea {
//     // -webkit-appearance: none;
//     background-color: var(--ds-background-input, #fff);
//     border: none;
//     border-radius: 3px;
//     box-shadow: inset 0 0 0 1px var(--ds-border-input, #091e4224);
//     box-sizing: border-box;
//     display: block;
//     line-height: 20px;
//     margin-bottom: 12px;
//     outline: none;
//     padding: 8px 12px;
//     transition-duration: 85ms;
//     transition-property: background-color, border-color, box-shadow;
//     transition-timing-function: ease;
// }
