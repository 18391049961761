$background: #22272b;
$background_2: #ae2e24;
$color_label: #ffd5d2;
$color_card_text: #b6c2cf;
$shadow_1: #091e4240;
$shadow_2: #091e424f;
$shadow-raised:
  0px 0px 0px 1px #00000000,
  0px 1px 1px #03040480,
  0px 0px 1px #03040480;
$text: #172b4d;

// Styles
.cardWidth{
  width: 100%;
}
.TaskCard {
  display: flex;
  flex-direction: column;
  position: relative; // ???
  row-gap: 8px;
  scroll-margin: 80px;
  width: 100%;

  .TaskCard__Wrap {
    min-height: 50px;
    position: relative;
    background-color: $background;
    border-radius: 8px;
    box-shadow: 1px 1px 1px 0px #738496;
    color: $color_card_text;
    cursor: pointer;
    scroll-margin: 8px;

    &:hover {
      opacity: 1;
      outline: 2px solid #85b8ff;
    }

    .CardView {
      display: flex;
      flex-direction: column;
      display: flow-root;
      position: relative;
      padding: 8px 12px 4px;
      min-height: 34px;

      .ColorLabel {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 4px;
        cursor: pointer;
        .ColorLabelWrap {
          display: inline-flex;
          max-width: calc(100% - 4px);
          border-radius: 4px;
          min-width: 40px;
          min-height: 8px;
          position: relative;
          color: #101204;
          padding: 0 8px;

          &:hover {
            opacity: 0.5;
            cursor: pointer;
          }
          .Label {
            display: none;
          }
          .LabelActive {
            display: inline-block;
            position: relative;
            margin-bottom: 0;
            padding: 0 8px;
            min-width: 56px;
            max-width: 56%;
            height: 16px;
            box-sizing: border-box;
            color: $color_label;
            line-height: 16px;
            font-size: 12px;
            font-weight: 500;
            text-align: left;
            vertical-align: middle;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .CardText {
        color: $color_card_text;
        text-decoration: none;
        display: block;
        margin-bottom: 4px;
        // overflow: hidden;
        // overflow-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 14px;
        text-align: start;
        text-overflow: ellipsis;
        width: 80%;
        white-space: nowrap;
      }
    }

    .CardEdit {
      position: absolute;
      top: 0;
      right: -40px;
      color: #fff;
      font-weight: 700;
    }
  }
}

.CardEditData {
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  padding: 5px;
  background-color: rgb(75, 70, 96);
  border-radius: 5px;
  &:hover {
    cursor: grab;
  }

  .EditFocus {
    width: 100%;
    resize: none;
    border-style: none;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid red;
    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}

.DraggingCard {
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  padding: 10px;
  height: 100px;
  min-height: 100px;
  border-color: #f43f5e;
  border-radius: 8px;
  border-width: 2px;
  opacity: 0.3;
  cursor: grab;
}
.cardIcon{
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
}

.cardGradient {
  background: linear-gradient(45deg, #22272b, #616161, #22272b);
  background-size: 200% 800%;
  animation: cardGradient 2s ease infinite;
  border-radius: 8px;
  cursor: default;
  z-index: 1;
}
@keyframes cardGradient {
  0% {
      background-position: 50% 0%;
  }
  50% {
      background-position: 50% 100%;
  }
  100% {
      background-position: 50% 0%;
  }
}
