
// стили для input
.Input input{
    margin-bottom: revert;
    transition: revert;
    border-width: 1px;
    box-shadow: none;
    border-color: #dfe1e6;
}
.Input{
    height: 32px;
    width: 200px;
    padding: 0px 12px 0px 30px;
    box-sizing: border-box;
    outline: none;
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    border: 2px solid #a6c5e229;
    box-shadow: none;
    background-color: #22272b;
    color: #8c9bab;
    
}
.Input:hover {
    background-color: #282e33;
}
.Input:focus {
    border: 2px solid #85b8ff;
    box-shadow: inset 0 0 0 1px #dfe1e6;
}
// стили для input