.InviteUserBoard {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  .Wrap {
    max-width: 800px;
    width: 100%;
    max-height: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: antiquewhite;
    color: #000;
    .Title {
      margin-top: 20px;
      font-size: 26px;
      font-weight: 600;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;



      .Link{
        color: red;
        font-size: 18px;
      }

      .LoginLink {
        margin-top: 15px;
        color: blue;
        font-size: 22px;
      }
    }
  }
}
