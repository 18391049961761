.buttonStyle {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 20px;
  // font-weight: 400;
  // font-display: swap;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  // justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 12px;
  text-decoration: none;
  white-space: normal;
  // background-color: var(--ds-background-neutral, #091e420f);
  box-shadow: none;
  border: none;
  // color: var(--ds-text, #172b4d);
  font-weight: 500;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
}

// $color: #579dff;
// $colorhvr: #85b8ff;

// .Btn {
//     background-color: #579dff;
//     border: 0px;
//     border-radius: 5%;
//     height: 32px;
//     width: 77px;
//     margin-top: -46px;
//     margin-left: 600px;
//     display: block;
// }
// .Btn:hover {
//     background-color: $colorhvr;
//     cursor: pointer;
// };

.buttonAdd {
  background-color: #579dff;
  box-shadow: none;
  border: none;
  color: #1d2125;
  font-weight: 500;

  &:hover {
    background-color: #85b8ff;
    box-shadow: none;
    border: none;
    color: #1d2125;
    text-decoration: none;
  }
  &:active {
    background-color: #cce0ff; //#09326c;
    box-shadow: none;
    border: none;
    color: #1d2125; //#ffffff;
    outline: 0;
  }
}

.buttonEsc {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 6px;
  position: relative;
  color: #dee4ea;

  &:hover {
    background-color: #a6c5e229;
    box-shadow: none;
    border: none;
    color: #b6c2cf;
    text-decoration: none;
  }
}

.BtnCreateNewColumn {
  display: flex;
  align-items: center;
  width: 272px;
  height: 44px;

  display: flex;
  cursor: pointer;
  padding: 12px;

  border-radius: 12px;
  background-color: #ffffff3d;

  &:hover {
    background-color: #a6c5e229;
  }
}
.BtnKebabColumnn {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 0px;
  padding: 8px;
  border-radius: 8px;
  color: #8c9bab;

  &:hover {
    background-color: #a6c5e229;
    color: #9fadbc;
  }

  &:active {
    background-color: #bfdbf847;
    color: #9fadbc;
  }
}

.BtnDeleteColumn {
  padding: 8px;
}
.cardWidth {
  width: 100%;
}
.BtnDownloadFile,
.BtnCloseSmallWindowLink,
.BtnUpdateLink,
.BtnDeleteFile,
.BtnCloseSmallWindowFile,
.BtnDeleteLink,
.BtnDeleteCard,
.BtnDeleteDueDate {
  width: 100%;
  height: 100%;
  padding: 0;
  justify-content: center;

  &:hover {
    opacity: 100%;
  }
}

.BtnCreateTask {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 200px;
  padding: 6px 12px 6px 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background-color: #a6c5e229;
  }
}

.BtnWorkspaceTitleButton {
  padding: 6px 12px;
}

.BtnWorkspaceTitleIconLetter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.BtnWorkspaceTitleNamePencil {
  margin-left: 4px;
  padding: 4px;

  &:hover {
    background-color: #a6c5e229;
  }
}

.BtnCreateNewDashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  & > div:first-child {
    font-size: 14px;
    margin-bottom: 8px;
  }
}
.BtnLogout {
  border: none;
  background-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px 12px;
  transition: none;
  border-radius: 0;
  outline: 0;
  background: #00000000;
  box-shadow: none;
  color: #b6c2cf;
  text-align: left;
  text-decoration: none;
  &:hover {
    border: none;
    background-color: transparent;
    box-shadow: none;
    background: #a1bdd914;
    color: #b6c2cf;
    text-decoration: none;
  }
}

.BtnLogin {
  height: 40px !important;
  line-height: 40px !important;
  -webkit-box-align: baseline;
  align-items: baseline;
  border-width: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  max-width: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition:
    background 0.1s ease-out 0s,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  white-space: nowrap;
  background: #0c66e4;
  cursor: pointer;
  height: 2.28571em;
  line-height: 2.28571em;
  padding: 0px 10px;
  vertical-align: middle;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  color: #ffffff !important;

  & > div,
  span {
    -webkit-box-pack: center;
    justify-content: center;
    display: flex !important;
  }
}

.BtnCardNameEdit {
  // display: none;
  position: absolute;
  /* z-index: 10; */
  top: -2px;
  right: -2px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #22272b;

  &:hover {
    background-color: #454f57;

    border: none;
    box-shadow: none;
    color: #b6c2cf;
    text-decoration: none;
  }
}
.BtnCardSubscribe {
  margin-top: 0;
  background-color: #a1bdd914;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  color: #b6c2cf;
  cursor: pointer;
  display: block;
  font-weight: 500;
  height: 32px;
  // margin-top: 8px;
  max-width: 300px;
  overflow: hidden;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  transition-duration: 85ms;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;

  display: flex;

  &:hover,
  &:active {
    border: none;
    box-shadow: none;
    color: #b6c2cf;
  }
  &:hover {
    background-color: #a6c5e229;
    text-decoration: none;
  }
  &:active {
    background-color: #bfdbf847;
    outline: 0;
  }
}
.btnWindowModal {
  // width: 50px;
  // height: 50px;
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  // background-color: #574949;
  z-index: 2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 7px;

  &:hover {
    background-color: #a6c5e229;
  }
}

.btnSmallWindow {
  padding: 5px;
  width: 100%;

  &:hover,
  &:active {
    border: none;
    box-shadow: none;
    color: #b6c2cf;
  }
  &:hover {
    background-color: #a6c5e229;
    text-decoration: none;
  }
  &:active {
    background-color: #bfdbf847;
    outline: 0;
  }
}

.addUserToCard {
  padding: 4px 8px 4px 4px;
  width: 100%;
  &:hover {
    background-color: #a1bdd914;
  }
}

.delUserFromCard {
  padding: 4px 8px 4px 4px;
  width: 100%;
  &:hover {
    background-color: #a1bdd914;
  }
}

.BtnShare {
  display: flex;
  align-items: center;
  width: 120px;
  height: 32px;
  background-color: #dcdfe4;
  margin: 0 4px;
  padding: 6px 12px 6px 8px;
  color: #172b4d;
}
.BtnCloseUserDashboard,
.BtnCloseUserCard {
  width: 36px;
  height: 36px;
  padding: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  border-radius: 0 12px;

  &:hover {
    filter: brightness(75%);
    background-color: #85b8ff;
  }
}
.BtnUserDashboardActions,
.BtnUserCardActions {
  display: block;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: #a1bdd914;
  }
}

.BtnFormShareDashboard {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    filter: brightness(75%);
  }
}

.BtnFormShareInput {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background-color: #579dff;
  margin-left: 10px;
  color: #1d2125;
  &:hover {
    background-color: #85b8ff;
  }
}

.btnWindowModalMainColAddUser {
  border-radius: 50%;
  padding: 6px;
  font-size: 16px;
  background-color: #a1bdd914;
  color: #b6c2cf;
  cursor: pointer;
  margin: 0 8px 8px 0;

  &:hover {
    background-color: #a6c5e229;
    border: none;
    box-shadow: none;
    color: #b6c2cf;
    text-decoration: none;
  }
  &:active {
    background-color: #bfdbf847;
    border: none;
    box-shadow: none;
    color: #b6c2cf;
    outline: 0;
  }
}
.btnWindowModalMainColAddLabel {
  padding: 6px;
  font-size: 16px;
  background-color: #a1bdd914;
  color: #b6c2cf;
  cursor: pointer;
  margin: 0 8px 8px 0;

  &:hover {
    background-color: #a6c5e229;
  }
  &:active {
    background-color: #bfdbf847;
  }
}

.cardEditorSave,
.cardDescriptionSave,
.attachmentSave,
.cardLabeTextSave {
  margin: 0 6px 0 0;
  border: none;
  background-color: #579dff;
  box-shadow: none;
  color: #1d2125;
  font-size: 14px;

  &:hover {
    border: none;
    background-color: #85b8ff;
    box-shadow: none;
    color: #1d2125;
    text-decoration: none;
  }
  &:active {
    border: none;
    outline: 0;
    background-color: #cce0ff;
    box-shadow: none;
    color: #1d2125;
  }
  &:disabled{
    color: #1d2125;
    background-color: rgb(77, 77, 77);
  }
}
.attachmentReset {
  margin: 0 6px 0 0;
  border: none;
  background-color: rgba(161, 189, 217, 0.0784313725);
  color: #ff6f6f;
  box-shadow: none;
  font-size: 14px;

  &:hover {
    border: none;
    background-color: #a6c5e229;
    box-shadow: none;
    color: #ff6f6f;
    text-decoration: none;
  }
  &:active {
    border: none;
    outline: 0;
    background-color: #bfdbf847;
    box-shadow: none;
    color: #ff6f6f;
  }
}
.cardDescriptionCancel,
.attachmentCancel {
  margin: 0 6px 0 0;
  border: none;
  background-color: rgba(161, 189, 217, 0.0784313725);
  color: #9fadbc;
  box-shadow: none;
  font-size: 14px;

  &:hover {
    border: none;
    background-color: #a6c5e229;
    box-shadow: none;
    color: #b6c2cf;
    text-decoration: none;
  }
  &:active {
    border: none;
    outline: 0;
    background-color: #bfdbf847;
    box-shadow: none;
    color: #b6c2cf;
  }
}

.BtnCardDescriptionChange{
  background-color: #a1bdd914;
  color: #b6c2cf;

  &:hover {
    background-color: #a6c5e229;
    color: #b6c2cf;
  }
  &:active {
    outline: 0;
    background-color: #bfdbf847;
    color: #b6c2cf;
  }
}
.BtnRedirectWorkspace {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #0c66e4;
  color: #fff;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    opacity: 0.70;
  }
}

.BtnCardActivity{
  background-color: #a1bdd914;
  color: #b6c2cf;

  &:hover {
    background-color: #a6c5e229;
    color: #b6c2cf;
  }
  &:active {
    outline: 0;
    background-color: #bfdbf847;
    color: #b6c2cf;
  }
}
.cardActivityCommentUpdate,
.cardActivityCommentDelete{
  font-size: 12px;
  padding: 0 6px;
  text-decoration: underline;
}

.dueDateSave{
  margin-bottom: 8px;
  border: none;
  background-color: #579dff;
  box-shadow: none;
  color: #1d2125;
  font-size: 14px;
  justify-content: center;

  &:hover {
    border: none;
    background-color: #85b8ff;
    box-shadow: none;
    color: #1d2125;
    text-decoration: none;
  }
  &:active {
    border: none;
    outline: 0;
    background-color: #cce0ff;
    box-shadow: none;
    color: #1d2125;
  }
}

.dueDateCancel{
  border: none;
  background-color: rgba(161, 189, 217, 0.0784313725);
  box-shadow: none;
  justify-content: center;

  &:hover {
    border: none;
    background-color: #a6c5e229;
    box-shadow: none;
    color: #b6c2cf;
    text-decoration: none;
  }
  &:active {
    border: none;
    outline: 0;
    background-color: #bfdbf847;
    box-shadow: none;
    color: #b6c2cf;
  }
}
.btnDelCard,
.btnDelComment{
  background-color: #f87168;
  border: none;
  box-shadow: none;
  color: #1d2125;
  width: 100%;
  justify-content: center;
}
.btnAddAttachment{
  background-color: #a1bdd914;
  color: #b6c2cf;

  &:hover {
    background-color: #a6c5e229;
    color: #b6c2cf;
  }
  &:active {
    outline: 0;
    background-color: #bfdbf847;
    color: #b6c2cf;
  }
}
.btnDelAttachment{
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  padding: 0;

  &:hover {
    //   border: none;
    background-color: #a6c5e229;
    //   box-shadow: none;
    color: #b6c2cf;
    //   text-decoration: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
  &:active {
    // outline: 0;
    background-color: #bfdbf847;
    color: #b6c2cf;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
}

.BtnLabelText{
  padding: 8px;
  cursor: pointer;

  &:hover {
    //   border: none;
    background-color: #a6c5e229;
    color: #b6c2cf;
    border-radius: 3px;
    display: flex;
    justify-content: center;
  }
  &:active {
    background-color: #bfdbf847;
    color: #b6c2cf;
    border-radius: 3px;
    display: flex;
    justify-content: center;
  }
}

.cardLabeTextCancel {
  margin: 0 6px 0 0;
  border: none;
  background-color: #f87168;
  color: #1d2125;
  box-shadow: none;
  font-size: 14px;

  &:hover {
    border: none;
    background-color: #fd9891;
    box-shadow: none;
    color: #1d2125;
    text-decoration: none;
  }
  &:active {
    border: none;
    outline: 0;
    background-color: #ffd5d2;
    box-shadow: none;
    color: #1d2125;
  }
}