.UserDashboard {
  width: 305px;
  max-height: 415px;
  background-color: #282e33;
  position: fixed;
  top: 48px;
  right: 15px;
  border-radius: 12px;
  color: #000;
  overflow-y: auto;
  overflow-x: none;

  .UserDashboardWrap {
    display: flex;
    flex-direction: column;
    padding: 0 12px 12px;
    position: relative;

    .UserDashboardButton {
      display: flex;
      justify-content: flex-end;
    }

    .UserDashboardInfoUser {
      display: flex;
      justify-content: flex-start;
      margin: 0 -12px;
      padding: 24px 12px 8px 14px;

      background-image: linear-gradient(to bottom, #579dff 0%, #579dff 100%);
      background-repeat: no-repeat;
      background-size: 100% 82px;
      border-radius: 8px;
      position: relative;

      .Logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 88px;
        height: 88px;
        color: #fff;
        border: 1.5px solid #282e33;
        border-radius: 50%;
        background-color: #2121ff;
        font-size: 40px;
        font-weight: 500;
        position: relative;
      }

      .InfoUserName {
        display: block;
        margin: 8px 0 0 8px;
        color: #282e33;
        .LastFirstName {
          font-size: 16px;
          font-weight: 500;
          text-overflow: ellipsis;
        }
        .UserName {
          font-size: 12px;
          text-overflow: ellipsis;
          font-weight: 600;
          .UserNameRole {
            color: #fff;
          }
        }
      }
    }

    hr {
      height: 1px;
      margin: 8px 0;
      background-color: #a6c5e229;
      border: none;
    }

    .UserDashboardActions {
      display: flex;
      flex-direction: column;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #b6c2cf;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -12px;
        margin-right: -12px;
        padding: 6px 0;
      }
    }
  }
}

.UserDashboard::-webkit-scrollbar {
  width: 10px;
}

.UserDashboard::-webkit-scrollbar-track {
  background-color: #323940;
  border-radius: 8px;
}

.UserDashboard::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 8px #455159;
  border-radius: 8px;
}
