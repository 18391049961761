.Content {
  padding-top: 48px;

  p {
    color: white;
    padding-left: 20px;
  }
}
.bgc{
  background-blend-mode: darken;
  background-color: #0000004d;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  min-width: 100vw;
  // background-image: url('../../../public/img/desert_xl.webp');
  // background-image: url('https://trello-backgrounds.s3.amazonaws.com/SharedBackground/1367x2048/8694436355c64f30caa17a94780a3693/photo-1713847177191-318d6ab96e62.webp');
}
.main_wrap{
  // display: flex;
  // flex-direction: column;
  // flex: 1 1 0%;
  // position: relative;
  // overflow-y: auto;

  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 0;
  position: relative;
} 
