.header {
    min-height: 32px;
    padding: 8px 52px 8px 56px;
    position: relative;
    grid-column: 1 / 5;
    grid-row: 1;
    z-index: 1;

    .headerIcon{
        left: 16px;
        position: absolute;
        top: 22px;
        color: #9fadbc;
        height: 32px;
        line-height: 32px;
        width: 32px;
        font-size: 24px;

        display: inline-block;
        font-family: trellicons;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        vertical-align: bottom;
    }

    .headerTitle{
        padding: 12px 0 0 6px;
        width: 95%;

        .title{
            margin-right: 4px;
            cursor: pointer;
            font-weight: 600;

        }
        
        textarea{
            resize: vertical;
            width: 100%;
            background: #0000;
            border-radius: 4px;
            box-shadow: none;
            font-size: 20px;
            font-weight: 600;
            height: 32px;
            line-height: 24px;
            margin: -6px -10px;
            min-height: 24px;
            padding: 6px 10px;
            resize: none;
            background-color: #22272b;
            border: none;
            border-radius: 3px;
            box-shadow: inset 0 0 0 1px #738496;
            box-sizing: border-box;
            display: block;
            line-height: 20px;
            margin-bottom: 12px;
            outline: none;
            padding: 8px 12px;
            transition-duration: 85ms;
            transition-property: background-color, border-color, box-shadow;
            transition-timing-function: ease;

            &:focus {
                background-color: #22272b;
                box-shadow: inset 0 0 0 2px #85b8ff;
            }
        }
    }
    .columnTitle{
        display: flex;
        align-items: center;
        cursor: default;
        width: fit-content;
        margin: 4px 8px 4px 2px;
        color: #9fadbc;
        font-size: 14px;

        .columnTitleName{
            cursor: default;
            margin: 4px 8px 4px 2px;
            color: #9fadbc;
        }
    }
}

.cardHeaderTitleGradient {
    background: linear-gradient(45deg, #22272b, #616161, #22272b);
    background-size: 200% 800%;
    animation: newCommentGradient 2s ease infinite;
    border-radius: 3px;
    cursor: no-drop!important;
}

@keyframes newCommentGradient {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}
