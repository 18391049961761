// .Icons {
//   width: 0;
//   height: 0;
// }

.BtnDropMenuIcon {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  padding-top: 3px;
}

.KebabMenuIcon {
  width: 20px;
  height: 20px;
}

.AtlassianIcon,
.Trello {
  width: 24px;
  height: 24px;
  fill: #fff;
  stroke: #fff;
}

.BtnCardDropdownStar {
  width: 10px;
  height: 10px;
  fill: #a1bdd914;
  stroke: #9fadbc;
  stroke-width: 3;

  &.IconActive {
    fill: #e2b203;
    stroke: #e2b203;
  }

  &:hover {
    fill: #e2b203;
    stroke: #e2b203;
  }
}

.IconCreateNewColumn {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: #fff;
}

.IconWindowModalMainColAddUser,
.IconWindowModalMainColAddLabel {
  width: 20px;
  height: 20px;
  color: #9fadbc;
}

.IconWindowModaSidebarAddLabel,
.IconWindowModalSidebarAddMembers{
  width: 16px;
  height: 16px;
  color: #9fadbc;
}

.IconWindowModalMainColActivity{
  width: 20px;
  height: 20px;
  color: #9fadbc;
  left: -40px;
  position: absolute;
}

.IconDeleteFile,
.IconDeleteColumnn {
  width: 20px;
  height: 20px;

  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5;
  stroke: #9fadbc;

  &:hover {
    stroke: #fff;
  }
}

.IconKebabColumnn {
  width: 16px;
  height: 16px;
  color: #9fadbc;
  margin: 8px;
}

.IconCreateTask {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5;
  fill: #9fadbc;
  // stroke: #9fadbc;
}

.google_logo {
  width: auto;
  height: auto;
}
.microsoft_logo {
  width: auto;
  height: auto;
}
.apple_logo {
  width: auto;
  height: auto;
}
.slack_logo {
  width: auto;
  height: auto;
}
.pencil_logo {
  // display: inline-block;
  // flex-shrink: 0;
  // line-height: 1;
  width: auto;
  height: auto;
}
.WorkspaceTitleButtonPeopleIcon {
  width: 16px;
  height: 16px;

  stroke-width: 0.1;
  stroke: #000;
}

.WorkspaceTitleVisibilityStatusIcon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  fill-rule: evenodd;
}

.WorkspaceTitleNamePencilIcon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  fill-rule: evenodd;
  margin-right: 2px;
}

.CardTextPencilLogo {
  width: 18px;
  height: 18px;
  fill: currentColor;
  fill-rule: evenodd;
  margin-right: 2px;
  color: #9fadbc;
}

.HelpCreateNewDashboardIcon {
  width: 20px;
  height: 20px;
  padding: 2px;
  fill: #9fadbc;
  stroke: #9fadbc;
  stroke-width: 2;
}
.btnModalCloseIcon {
  height: 20px;
  width: 20px;
  line-height: 20px;
  // margin-right: 8px;
  color: #8c9bab;
  font-weight: 500;
}
.iconCardSubscribe {
  margin: 0 6px 0 -6px;
  color: #9fadbc;
  font-size: 16px;
  // height: 20px;
  line-height: 20px;
  width: 20px;

  // display: inline-block;
  font-family: trellicons;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  // vertical-align: bottom;
}

.iconSelected {
  width: 26px;
  height: 26px;
}

.iconSelectedLabel {
  width: 16px;
  height: 16px;
}

.itemDueDateIcon {
  height: 16px;
  width: 16px;
  line-height: 20px;
  color: #9fadbc;
  font-weight: 500;
}

.delUserFromCardIcon {
  height: 14px;
  width: 14px;
  line-height: 20px;
  color: #8c9bab;
  font-weight: 500;
}

.IconShareDashboard {
  width: 16px;
  height: 16px;
  color: #172b4d;
}

.IconCloseUserDashboard,
.IconCloseUserCard {
  width: 16px;
  height: 16px;
  color: #282e33;
  stroke-width: 2;
}

.IconCloseFormShare {
  width: 24px;
  height: 24px;
  color: #9fadbc;
  stroke-width: 2;
}

.IconAdmin {
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  fill: #fff;
  stroke-width: 40;
  stroke: #fff;
}

.IconRedirectWorkspace {
  width: 25px;
  height: 25px;
  // fill: #9fadbc;
  fill:#fff;
  margin-right: 5px;
}
.iconAttachment{
  height: 16px;
  width: 16px;
  line-height: 20px;
  color: #9fadbc;
  font-weight: 500;
}
.iconAttachmentBig{
  position: absolute;
  top: 12px;
  left: -36px;
  height: 24px;
  width: 24px;
  line-height: 20px;
  color: #9fadbc;
  font-weight: 500;
}
.iconLink{
  width: 20px;
  height: 22px;
}

.IconDownloadFile{
  width: 24px;
  height: 24px;
}