
.iconWrap{
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  &:hover {
    border: none;
    background-color: #a6c5e229;
    box-shadow: none;
    color: #b6c2cf;
    text-decoration: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
}

.Dragging {
  background: #101204;
  opacity: 0.2;
  border-radius: 12px;
  border: 2px solid rgb(254, 114, 114);
  display: flex;
  flex-direction: column;
  width: 272px;
  height: 430px;
  // max-height: 500px;
}

.Column {
  // background: linear-gradient(#1012041a 10%, #101204 90%);
  background: linear-gradient(200deg, rgba(16, 18, 4, 0.1019607843) 10%, #101204 90%);
  width: 272px;
  height: 430px; //TODO 500px в идеале height: 100%;
  min-height: 100px;
  max-height: 500px; // 500px
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #101204;
  box-shadow: 1px 1px 1px 0px #738496;

  .ColumnOptionWindow{
    position: absolute;
    // inset: 163.889px auto auto 692.778px;
    width: 270px;
    border-radius: 8px;
    background-color: #282e33;
    box-shadow: 0px 8px 12px #091e4226, 0px 0px 1px #091e424f;
    padding-bottom: 12px;
    top: 48px;
    right: 8px;
    font-size: 14px;
    z-index: 1;

    .ColumnOptionWindowHeader{
      display: grid;
      // display: flex;
      position: relative;
      grid-template-columns: 32px 1fr 32px;
      align-items: center;
      padding: 4px 8px;
      text-align: center;
      &::after {
        content: ' ';
        display: block;
        height: 1px;
        margin: 8px 0;
        background-color: #a6c5e229;
        grid-column: 1 / span 3;
        grid-row: 2;
    }

      .ColumnOptionWindowHeaderTitle{
        display: block;
        position: relative;
        grid-column: 1 / span 3;
        grid-row: 1;
        height: 40px;
        margin: 0;
        padding: 0 32px;
        overflow: hidden;
        color: #9fadbc;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.003em;
        line-height: 40px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
      }
      .ColumnOptionWindowHeaderIcon{
        grid-column: 3;
        grid-row: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        color: #8c9bab;
        z-index: 2;

        &:hover {
          border: none;
          background-color: rgba(166, 197, 226, 0.1607843137);
          box-shadow: none;
          color: #b6c2cf;
          text-decoration: none;
          border-radius: 8px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .ActionsWrap{
      margin: 0;

      .ActionsWrapUl{
        margin: 0;
        padding: 0;
        list-style: none;

        .ActionsWrapLi{
          background-color: transparent;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 8px 24px;
          border-radius: 0;
          outline: 0;
          background: #00000000;
          box-shadow: none;
          color: #b6c2cf;
          text-align: left;
          cursor: pointer;
          display: flex;
          justify-content: space-around;

          &:hover {
            border: none;
            background-color: transparent;
            box-shadow: none;
            background: #a1bdd914;
            // color: #b6c2cf;
            color: #ff6f6f;
            text-decoration: none;
          }
          &:active {
            border: none;
            background-color: transparent;
            box-shadow: none;
            background: #a6c5e229;
            color: #b6c2cf;
          }
        }
      }
    }
  }
}
.topWrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 4px;
  background: #101204;
  border-radius: 12px 12px 0 0;
}
.ColumnTitleWrap {
  cursor: pointer;
  width: 80%;

  .ColumnTitle {
    padding: 6px 8px 6px 12px;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .EditeColumnTitle {
      background: #000;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 4px;
      // outline: none;
      &:focus {
        border-color: #fff;
      }
    }
  }
}

.ColumnCardsWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px; // расстояние между карточек
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;

  .FormCreateCard {
    form {
      width: 100%;
      padding: 7px;
      border-radius: 8px;
      textarea {
        border-radius: 4px;
        min-height: 36px;
        max-height: 160px;
        margin: 0;
        padding: 8px 12px;
      }
    }
  }
}

.BtnCreateTaskWrap {
  display: flex;

  // padding: 8px 8px 0 8px;
  padding: 8px;
  // margin-bottom: 10px;
  background: #101204;
  border-radius: 0 0 12px 12px;
}

.none {
  display: none;
}

.ColumnCardsWrap::-webkit-scrollbar {
  width: 8px;
}

.ColumnCardsWrap::-webkit-scrollbar-track {
  background-color: #323940;
  border-radius: 8px;
}

.ColumnCardsWrap::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 8px #898e91;
  border-radius: 8px;
}
