.cardDetailNotifications{
    height: 64px;
    
    .cardDetailsTitle{
        color: #9fadbc;
        display: block;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        line-height: 20px;
        margin: 0 8px 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .membersList{
        display: flex;
        
        .memberMenu{
            cursor: pointer;
            margin: 0 4px 4px 0;
            border-radius: 50%;
            height: 32px;
            position: relative;

            &:hover {
                background-color: #454f59;
                color: #b6c2cf;
            }
            &:active {
                background-color: #596773;
                color: #b6c2cf;
            }
        }
        .memberAvatar{
            border-radius: 50%;
            height: 32px;
            object-fit: cover;
            width: 32px;
            background-color: #2c5683;
            border: 1px solid #282e33;

            &:hover {
                opacity: .8;
            }
            &:active {
                opacity: .7;
            }
        }
        .memberAvatarSpan{border-radius: 50%;
            border-radius: 50%;
            height: 32px;
            object-fit: fill;
            width: 32px;
            background-color: #2121ff;
            border: 1px solid #282e33;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
            color: #d7d7d7;

            &:hover {
                opacity: .8;
            }
            &:active {
                opacity: .7;
            }
        }
    } 
}
