.KanbanBoard {
  margin-top: 15px;
  margin-top: 65px; // TODO временный стиль
  margin-left: 20px; // временный стиль
  margin-right: 20px; // временный стиль
  color: #9fadbc;
  height: 100%;

  .Wrap {
    display: flex;
    padding: 2px 6px 8px 6px;
    height: 100%;
    scrollbar-color: #ffffff4d #00000026;
    scrollbar-width: auto;
    overflow-x: auto;
    overflow-y: hidden;

    .Container {
      display: flex;
      // align-items: flex-start; // TODO при перестроении колонки расстягиваются
      gap: 1rem;
      height: 100%;
    }

    .BtnCreateNewColumn__Wrap {
      display: flex;
      padding: 0 6px;

      font-size: 14px;
      font-weight: 500;
      color: #ffffff;

      // .BtnCreateNewColumn {
      //   display: flex;
      //   align-items: center;
      //   width: 272px;
      //   height: 44px;

      //   display: flex;
      //   cursor: pointer;
      //   padding: 12px;

      //   border-radius: 12px;
      //   background-color: #ffffff3d;

      //   &:hover {
      //     background-color: #A6C5E229;
      //   }
      // }
    }
  }
}

.none {
  display: none;
}
