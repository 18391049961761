.cardAttachment{

    .cardAttachmentWrap{
        clear: both;
        margin-bottom: 24px;
        position: relative;

        .cardAttachmentHeader{
            align-items: center;
            display: flex;
            justify-content: space-between;
            min-height: 32px;
            margin: 0 0 4px 40px;
            padding: 8px 0;
            position: relative;

            .cardAttachmentHeaderTitle{
                display: inline-block;
                margin: 0;
                min-height: 18px;
                min-width: 40px;
                width: auto;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                cursor: default;
            }
        }

        .cardAttachmentContent{
            margin-left: 40px;

            .contentCardsWrap{
                margin-bottom: 20px;

                .contentCardsTitle{
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    margin: 0 0 8px;
                }
                .contentCardsList{
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 24px 8px;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    .contentCardWrap{
                        display: flex;
                        flex-direction: column;

                        .contentCard{
                            font-size: 14px;
                        }
                        .contentCardOptions{
                            display: flex;
                            align-self: flex-end;
                            margin-top: 8px;
                            padding-right: 8px;
                            column-gap: 8px;

                            .contentCardOptionsTittle{
                                display: flex;
                                align-items: center;
                                column-gap: 2px;
                                font-size: 14px;
                            }

                            .contentCardActions{
                                position: relative;
                                // z-index: 1;
                            }
                        }
                    }
                }
            }

            .contentLinksWrap{
                margin-bottom: 20px;

                .contentLinksTittle{
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                }
                .contentLinksList{
                    border-radius: 4px;
                    background: #1d2125;
                    box-shadow: 0px 1px 1px #091e4240, 0px 0px 1px #091e424f;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    .contentLinkWrap{
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 6px 8px;
                        column-gap: 16px;

                        .contentLinkContent{
                            display: flex;
                            gap: 0.5rem 0px;
                            flex-direction: column;
                            min-width: 0px;
                            width: 100%;
                            overflow-x: hidden;
                            position: relative;
                            --container-padding: 0rem;
                            --container-gap-left: 0rem;
                            --container-gap-right: 0rem;
                            --preview-block-width: 30%;
                            padding: 0rem;

                            .contentLinkLink{
                                position: absolute;
                                inset: 0px;
                                overflow: hidden;
                                text-indent: 100%;
                                white-space: nowrap;
                                color: #579dff;
                                text-decoration: none;
                                background-color: initial;
                            }
                            .contentLinkInfo{
                                display: flex;
                                gap: 0.5rem;
                                line-height: 1rem;
                                min-width: 0px;
                                overflow: hidden;
                                flex-direction: row;
                                -webkit-box-align: center;
                                align-items: center;
                                color: inherit;
                                -webkit-tap-highlight-color: transparent;
                                user-select: none;
                                -webkit-box-pack: start;
                                justify-content: flex-start;
                                outline: none !important;

                                .contentLinkInfoImg{
                                    align-self: center;
                                    min-width: fit-content;
                                    margin: 0px;
                                    flex: 0 0 auto;
                                    // height: 1rem;
                                    // min-height: 1rem;
                                    // max-height: 1rem;
                                    // width: 1rem;
                                    // max-width: 1rem;

                                    img {
                                        height: 16px;
                                        min-height: 16px;
                                        max-height: 16px;
                                        width: 16px;
                                        min-width: 16px;
                                        max-width: 16px;
                                    }

                                    .link_first_letter {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 24px;
                                        height: 24px;
                                        color: #d7d7d7;
                                        border: 1.5px solid #282e33;
                                        border-radius: 50%;
                                        background-color: #2121ff;
                                        font-size: 16px;
                                        font-weight: 500;
                                
                                        .memberAvatar{
                                          border-radius: 50%;
                                          width: 100%;
                                          height: 100%;
                                          object-fit: cover;
                                          background-color: #9fadbc;
                                        }
                                    }

                                }
                                .contentLinkInfoLink{
                                    position: relative;
                                    // z-index: 1;
                                    font-size: 0.875rem;
                                    font-weight: 400;
                                    letter-spacing: -0.003em;
                                    line-height: 1rem;
                                    display: -webkit-box;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    word-break: break-word;
                                    // -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    color: #579dff;
                                }
                            }
                        }
                        .contentLinkActions{
                            position: relative;
                            // z-index: 1;
                        }
                    }
                }
            }

            .contentFilesWrap{
                margin-bottom: 20px;

                .contentFilesTittle{
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    margin: 0 0 8px;
                }
                .contentFilesList{
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    .contentFileWrap{
                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;
                        cursor: default;

                        .contentFileContent{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-right: 8px;
                            column-gap: 12px;
                            width: 100%;

                            .contentFileLink{
                                flex-shrink: 0;
                                width: 64px;
                                height: 48px;
                                border-radius: 4px;
                                background-repeat: no-repeat;
                                background-position: center;
                                // background-size: contain;
                                background-size: 40px;
                                // box-shadow: 0px 1px 1px #091e4240, 0px 0px 1px #091e424f;
                                box-shadow: 0px 0px 0px 1px #00000000, 0px 1px 1px #03040480, 0px 0px 1px #03040480;
                                cursor: pointer;
                                color: #579dff;
                                text-decoration: none;

                                .contentFileLinkText{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    min-width: 64px;
                                    height: 100%;
                                    overflow: hidden;
                                    color: #9fadbc;
                                    font-size: 14px;
                                    font-weight: bold;
                                    line-height: 80px;
                                    text-transform: uppercase;
                                }
                            }

                            .contentFileInfo{
                                flex-grow: 1;
                                cursor: default;

                                .contentFileInfoTitle{
                                    display: flex;
                                    align-items: center;
                                    column-gap: 4px;
                                    font-weight: 600;
                                    line-height: 20px;
                                }
                                .contentFileInfoAddDate{
                                    margin: 0;
                                    color: #9fadbc;
                                    font-size: 12px;
                                }
                            }

                            .contentFileActions{
                                display: flex;
                                flex-shrink: 0;
                                align-items: center;
                                column-gap: 4px;
                                position: relative;
                                // z-index: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.contentLinksList li{
    border-top: 1px solid #a6c5e229;
}
.contentLinksList li:first-child{
    border: none;
}

.smallWindowOptionsWrap{
    background-color: #282e32;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #39424a, 0px 8px 12px #0304045C, 0px 0px 1px 1px #03040480;
    overflow: hidden;
    position: absolute;
    width: 232px;
    height: max-content;
    min-height: 30px;
    cursor: default;
    top: 0px;
    // left: 0;
    right: 0;
    bottom: 0;
    padding: 12px;
    z-index: 3;
}

.smallWindowWrap{
    background-color: #282e32;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #39424a, 0px 8px 12px #0304045C, 0px 0px 1px 1px #03040480;
    overflow: hidden;
    position: absolute;
    width: 304px;
    min-height: 30px;
    cursor: default;
    top: 40px;
    right: 0;
    z-index: 1;

    .itemHeader{
        align-items: center;
        display: grid;
        grid-template-columns: 32px 1fr 32px;
        padding: 4px 8px;
        position: relative;
        text-align: center;
        
        .itemHeaderTitle{
            box-sizing: border-box;
            color: #9fadbc;
            display: block;
            font-size: 14px;
            font-weight: 600;
            grid-column: 1 / span 3;
            grid-row: 1;
            height: 40px;
            letter-spacing: -.003em;
            line-height: 16px;
            line-height: 40px;
            margin: 0;
            overflow: hidden;
            padding: 0 32px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            z-index: 1;
        }
        .iconWrap{
            border-radius: 8px;
            color: #626f86;
            grid-column: 3;
            grid-row: 1;
            padding: 3px;
            z-index: 2;
            display: flex;
        }
    }
    .delButtonWrap{
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 12px 12px;

        .delButtonWrapText{
            margin: 0 0 8px;
            font-size: 14px;
        }
    }
}

.actionAttachmentWrap li:first-child {
    margin: 0;
}
.actionAttachment {
    background-color: #a1bdd914;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    box-sizing: border-box;
    color: #b6c2cf;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    height: 32px;
    margin-top: 8px;
    max-width: 300px;
    // padding: 6px 12px;
    font-size: 14px;

    &:active,
    &:hover {
        border: none;
        box-shadow: none;
        color: #b6c2cf;
    }
    &:hover {
        background-color: #a6c5e229;
        text-decoration: none;
    }
    &:active{
        background-color: #bfdbf847;
        outline: 0;
    }

    .actionDeleteCardText{
        margin-right: 8px;
    }
}

.cardAttachmentGradient {
    background: linear-gradient(45deg, #22272b, #616161, #22272b);
    background-size: 200% 800%;
    animation: fileLinkGradient 2s ease infinite;
    border-radius: 3px;
    cursor: default;
}
@keyframes fileLinkGradient {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}