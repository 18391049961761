$text: #172b4d;
$text_2: #b6c2cf;
$background: #091e420f;
$background_1: #cc4223;
$background_2: #fea362;
$background_2: #fea362;

.CardDropdownMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  margin: 0 0 4px;
  color: $text_2;
  cursor: pointer;

  .Icon {
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $background;
    overflow: hidden;

    .IconLetter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      background: linear-gradient($background_1, $background_2);
    }
  }

  .CardTitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    margin: 0 0 0 12px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
