.cardDetailNotifications{
    height: 64px;
    
    .cardDetailsTitle{
        color: #9fadbc;
        display: block;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        line-height: 20px;
        margin: 0 8px 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dueDateItemWrap{
        align-items: center;
        display: flex;
        flex-direction: row;
        margin: 0 8px 8px 0;

        .dueDateItem{
            box-sizing: border-box;
            // width: 100%;
            margin-right: 8px;
            // padding: 0 0 4px 4px;
            cursor: pointer;
            display: inline-flex;
            position: relative;
            align-items: center;
            
            .dueDateItemInput{
                // -webkit-appearance: checkbox;
                display: inline;
                margin-right: 4px;
        
                box-sizing: border-box;
        
                width: 1px;
                height: 1px;
                margin: 0;
                padding: 0;
                overflow: hidden;
                clip: rect(1px, 1px, 1px, 1px);
                border: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
            }
            
            .dueDateItemCheckboxWrap{
                position: relative;
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                overflow: hidden;
                transition: all 0.2s ease-in-out;
                border-radius: 2px;
                white-space: nowrap;
                background-color: #22272b;
                box-shadow: inset 0 0 0 1px #738496;
        
                // &:checked{
                //     background-color: #579dff;
                //     box-shadow: inset 0 0 0 1px #579dff;
                // }
        
                .dueDateItemCheckbox{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    opacity: 0;
                }
            }
        }
        .checked{
            background-color: #579dff!important;
            box-shadow: inset 0 0 0 1px #579dff!important;
            opacity: 1!important;
        }


    }
}

.itemDueDateExecute{
    background-color: #4bce97;
    color: #1d2125;
    margin: auto 0 auto 8px;
    padding: 0 4px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 16px;
}

.cardDueDateWindowGradient {
    background: linear-gradient(45deg, #22272b, #616161, #22272b);
    background-size: 200% 800%;
    animation: dueDateWindowGradient 2s ease infinite;
    border-radius: 3px;
    cursor: default;
}

@keyframes dueDateWindowGradient {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}