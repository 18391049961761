.smallWindowWrap{
    background-color: #282e32;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #39424a, 0px 8px 12px #0304045C, 0px 0px 1px 1px #03040480;
    overflow: hidden;
    position: absolute;
    width: 304px;
    min-height: 30px;
    cursor: default;
    top: -30px;
    right: 200px;

    .itemHeader{
        align-items: center;
        display: grid;
        grid-template-columns: 32px 1fr 32px;
        padding: 4px 8px;
        position: relative;
        text-align: center;
        
        .itemHeaderTitle{
            box-sizing: border-box;
            color: #9fadbc;
            display: block;
            font-size: 14px;
            font-weight: 600;
            grid-column: 1 / span 3;
            grid-row: 1;
            height: 40px;
            letter-spacing: -.003em;
            line-height: 16px;
            line-height: 40px;
            margin: 0;
            overflow: hidden;
            padding: 0 32px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            z-index: 1;
        }
        .iconWrap{
            width: fit-content;
            height: fit-content;
            border-radius: 8px;
            color: #626f86;
            grid-column: 3;
            grid-row: 1;
            padding: 3px;
            z-index: 2;
            display: flex;
        }
    }

    .labelWrap{
        max-height: 648.014px;
        padding-top: 0;
        -webkit-overflow-scrolling: touch;
        padding: 12px;
        overflow-x: hidden;
        overflow-y: auto;

        .labelList {
            padding: 4px 0 8px;
            list-style: none;
            margin: 0;

            li{

                .labelItem{
                    box-sizing: border-box;
                    width: 100%;
                    margin: 0;
                    padding-left: 4px;
                    margin-bottom: 4px;
                    cursor: pointer;
                    display: inline-flex;
                    position: relative;
                    align-items: center;
                    
                    .labelItemInput{
                        display: inline;
                        margin-right: 4px;
                        box-sizing: border-box;
                        width: 1px;
                        height: 1px;
                        margin: 0;
                        padding: 0;
                        overflow: hidden;
                        clip: rect(1px, 1px, 1px, 1px);
                        border: 0;
                        pointer-events: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    
                    .labelItemCheckboxWrap{
                        position: relative;
                        flex-shrink: 0;
                        width: 16px;
                        height: 16px;
                        overflow: hidden;
                        transition: all 0.2s ease-in-out;
                        border-radius: 2px;
                        white-space: nowrap;
                        background-color: #22272b;
                        box-shadow: inset 0 0 0 1px #738496;

                        .labelItemCheckbox{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 16px;
                            height: 16px;
                            opacity: 0;
                        }
                    }

                    .labelItemColor{
                        flex-grow: 1;
                        min-width: 0;
                        margin-left: 12px;

                        .labelItemColorDiv{
                            margin-bottom: 0px;
                            display: flex;
                            flex-grow: 1;
                            align-items: center;
                            min-width: 0;
                            gap: 4px;

                            .labelItemColorSpan{
                                width: 100%;
                                display: inline-block;
                                position: relative;
                                box-sizing: border-box;
                                min-width: 48px;
                                max-width: 100%;
                                height: 32px;
                                margin-bottom: 0;
                                padding: 0 12px;
                                overflow: hidden;
                                border-radius: 3px;
                                color: #22272b; // #baf3db;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 32px;
                                text-align: left;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}

.labelListLi{
    margin: 4px 0 8px;
    border: 1px solid #738496;
    border-radius: 3px;
    padding: 12px 8px 0px;
    background-color: #323940;
}

.checked{
    background-color: #579dff!important;
    box-shadow: inset 0 0 0 1px #579dff!important;
    opacity: 1!important;
}


// .iconWrap{
//     width: fit-content;
//     height: fit-content;
  
//     &:hover {
//       border: none;
//       background-color: #a6c5e229;
//       box-shadow: none;
//       color: #b6c2cf;
//       text-decoration: none;
//       border-radius: 8px;
//       display: flex;
//       justify-content: center;
//     }
// }

.labelItemWait{
    cursor: wait!important;
}

.cardLabelWindowInputGradient {
    background: linear-gradient(45deg, #22272b, #616161, #22272b);
    background-size: 200% 800%;
    animation: labelGradient 2s ease infinite;
    border-radius: 3px;
    cursor: default;
    z-index: 9;
    position: relative;
}

@keyframes labelGradient {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}

.labeTextButtonWrap{
    margin: 4px 0px 12px 32px;
}

.labeTextInputWrap{
    -webkit-box-align: center;
    align-items: center;
    background-color: #22272b;
    border-color: #738496;
    color: #b6c2cf;
    cursor: text;
    border-radius: 3px;
    border-width: 1px;
    padding: 1px 0;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    // font: var(--ds-font-body, normal 400 14px / 20px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif);
    -webkit-box-pack: justify;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    overflow-wrap: break-word;
    vertical-align: top;
    pointer-events: auto;
    margin-bottom: 8px;

    .labeTextInput{
        padding: 4px 6px;
        margin: unset;
        box-shadow: unset;
        text-overflow: ellipsis;

        background-color: #00000000;
        border: 0px;
        box-sizing: border-box;
        color: inherit;
        cursor: inherit;
        font: normal 400 14px / 20px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif;
        min-width: 0px;
        outline: none;
        width: 100%;
    }
}