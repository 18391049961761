.UserCard {
  width: 305px;
  max-height: 415px;
  background-color: #282e33;
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 12px;
  color: #000;
  overflow-y: auto;
  overflow-x: none;
  cursor: default;
  z-index: 1;

  .UserCardWrap {
    display: flex;
    flex-direction: column;
    padding: 0 12px 12px;
    position: relative;

    .UserCardButton {
      display: flex;
      justify-content: flex-end;
    }

    .UserCardInfoUser {
      display: flex;
      justify-content: flex-start;
      margin: 0 -12px;
      padding: 24px 12px 8px 14px;

      background-image: linear-gradient(to bottom, #579dff 0%, #579dff 100%);
      background-repeat: no-repeat;
      background-size: 100% 82px;
      border-radius: 8px;

      .Logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 88px;
        height: 88px;
        color: #d7d7d7;
        border: 1.5px solid #282e33;
        border-radius: 50%;
        background-color: #2121ff;
        font-size: 40px;
        font-weight: 500;

        .memberAvatar{
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-color: #9fadbc;
        }
      }

      .InfoUserName {
        display: block;
        margin: 8px 0 0 8px;
        color: #282e33;
        .LastFirstName {
          font-size: 16px;
          font-weight: 500;
          text-overflow: ellipsis;
        }
        .UserName {
          font-size: 12px;
          text-overflow: ellipsis;
        }
      }
    }

    hr {
      height: 1px;
      margin: 8px 0;
      background-color: #a6c5e229;
      border: none;
    }

    .UserCardActions {
      display: flex;
      flex-direction: column;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #b6c2cf;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -12px;
        margin-right: -12px;
        padding: 6px 0;
      }
    }
  }
}

.UserCard::-webkit-scrollbar {
  width: 10px;
}

.UserCard::-webkit-scrollbar-track {
  background-color: #323940;
  border-radius: 8px;
}

.UserCard::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 8px #455159;
  border-radius: 8px;
}

.userCardGradient {
  background: linear-gradient(45deg, #22272b, #616161, #22272b);
  background-size: 200% 800%;
  animation: newCommentGradient 2s ease infinite;
  border-radius: 3px;
  cursor: no-drop!important;
}

@keyframes newCommentGradient {
  0% {
      background-position: 50% 0%;
  }
  50% {
      background-position: 50% 100%;
  }
  100% {
      background-position: 50% 0%;
  }
}