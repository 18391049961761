$color: #161a1d;
$color1: #9fadbc;
$color2: #a6c5e229;
$color3: #1d2125;
$color4: #2121ff;
$color5: #454f59;
$color6: #85B8FF;

.Notificate {
  display: block;
  padding: 4px;
  flex-shrink: 0;
  border-style: none;
  line-height: 1;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: $color4;
  position: relative;

  &:hover {
    background-color: $color6;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .NotificateUserName {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    position: relative;
  }
}

.HeaderUsers {
  width: 28px;
  height: 28px;
}

.adminLabel{
  // bottom: 0;
  width: 9px;
  height: 9px;
  background-image: url('../../../../public/img/admin_label.png');
  position: absolute;
  z-index: 1;
  // right: -6px;
  top: 15px;
  background-size: 100%;
}
