.cardDetailNotifications{
    height: 64px;
    
    .cardDetailsTitle{
        color: #9fadbc;
        display: block;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        line-height: 20px;
        margin: 0 8px 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .labelsList{
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .labelElement{
            display: inline-block;
            position: relative;
            box-sizing: border-box;
            min-width: 48px;
            max-width: 100%;
            height: 32px;
            margin-bottom: 0;
            padding: 0 12px;
            overflow: hidden;
            border-radius: 3px;
            color: #22272b;
            font-size: 14px;
            font-weight: 500;
            line-height: 32px;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
                background-color: #164b35;
                cursor: pointer;
            }
            &:focus {
                outline: -webkit-focus-ring-color auto 5px;
                outline: #85b8ff auto 2px;
            }
        }
    }
}
