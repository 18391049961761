
.wrapPortal{
    width: 100%;
    cursor: pointer;
    
    &:hover {
        border: none;
        background-color: #a6c5e229;
        box-shadow: none;
        color: #b6c2cf;
        text-decoration: none;
        border-radius: 8px;
    }
}
.Modal {
    background-color: rgba(5, 0, 0, 0.3);
    display: flex;
    position: fixed;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    align-items: flex-start;
    outline: none;
    border-radius: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.Overlay {
    background-color: transparent;
}
.wrap{
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    overflow: auto;
    align-items: flex-start;
    // margin: 40px auto;
    margin: auto;
    top: 50px;
    outline: none;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
