.DashboardHeader {
  backdrop-filter: blur(4px);
  background-color: #0000003d;
  width: 100%;
  height: auto;
  padding-bottom: 0;
  position: fixed;
  top: 47px;
  z-index: 1;

  .DashboardHeaderWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    padding: 12px 10px 12px 16px;

    .DashboardHeaderLeft {
      display: flex;
      position: relative;
      flex-wrap: nowrap;
      align-items: flex-start;
      max-width: 100%;
      min-height: 32px;

      .LeftTitle {
        display: inline-flex;
        position: relative;
        max-width: 100%;
        height: 32px;
        margin-right: 4px;
        margin-bottom: 0;
        border-radius: 3px;
        line-height: 32px;
        white-space: nowrap;
        cursor: default;

        .TitleName {
          height: 100%;
          margin-bottom: 12px;
          padding: 0 10px;
          overflow: hidden;
          background: transparent;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          line-height: 32px;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .DashboardHeaderRight {
      display: flex;
      position: relative;

      .DashboardHeaderUsers {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .User {
          margin-left: 2px;
          .UserDashboardWrap {
            display: block;
            // width: 305px;
            // height: 265px;
            // background-color: rgb(182, 181, 181);
            // position: fixed;
            // top: 48px;
            // right: 5px;
            // border-radius: 12px;
          }
        }
      }

      .ButtonShare {
        position: relative;
      }

      .FormShare {
        // display: none;
        width: 450px;
        min-height: 180px;
        position: absolute;
        top: -3px;
        right: 3px;
        padding: 20px 0px 0px 24px;
        background-color: #282e33;
        border-radius: 8px;

        .FormShareTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          margin: 0px 24px 16px 0px;
        }
        .FormShareInput {
          display: flex;
          flex-wrap: nowrap;
          // input {
          //   width: 285px;
          //   height: 40px;
          //   border-radius: 3px;
          //   padding: 6px 4px 6px 12px;
          //   border: none;
          //   background-color: #22272b;
          //   box-shadow: inset 0 0 0 1px #738496;
          //   &:focus {
          //     background-color: #22272b;
          //     border: #0000;
          //     box-shadow: inset 0 0 0 1px #388bff;
          //   }
          // }
        }
        .LineSeparator {
           width: calc(100% - 24px);
           height: 4px;
           background-color: #0052CC;
           margin: 20px 0 10px 0;
        }
        .Title_ListUsers {
          font-weight: 600;
          color: #fff;

        }
      }
    }
  }
}

.DisplayNone {
  display: none;
}

.DisplayBlock {
  display: block;
}
