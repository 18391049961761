$active: #579dff;
$background_primary: #1d2125;
$background: #282e33;
$background_hover: #1c2b41;
$border: hsla(211, 18%, 68%, 0.16);
$border_line: #a6c5e229;
$button: #9fadbc;
$button_color: #1d2125;
$hover_icon: #2a2f3529;
$hover_li: #a1bdd914;
$hover_li_2: #a6c5e229;
$shadow_1: #39424a;
$shadow_2: #0304045c;
$shadow_3: #03040480;
$text: #b6c2cf;
$transition_hover: all 0.3s ease;

// Mixins
@mixin flex-jfc-ai-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin no-touch-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none; // Safari
  -moz-user-select: none; // Mozila
  -ms-user-select: none; // IE 10 and IE 11
  user-select: none; // Standard syntax
}

@mixin drop_down_menu {
  display: block;
  position: absolute;
  top: 41px;
  right: auto;
  left: 0;
  bottom: auto;

  width: 280px;
  min-height: 45px;
  max-height: 305px;
  border-radius: 8px;
  z-index: 999;
  background-color: $background;
  box-shadow:
    0px 0px 0px 1px $shadow_1,
    0px 8px 12px $shadow_2,
    0px 0px 1px 1px $shadow_3;

  padding: 12px;

  overflow-y: auto; // скрол
}

@mixin li-hover {
  li {
    border-radius: 8px;
    transition: $transition_hover;

    &:hover {
      background-color: $hover_li;
      transition: $transition_hover;
    }
  }
}

// Styles
.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  min-height: 48px;
  max-height: 48px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text;

  background-color: $background_primary;

  border-bottom: 1px solid $border;
}

.Navigation {
  display: flex;
  max-height: 48px;
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid $border;
  backdrop-filter: blur(6px);

  .KebabMenu {
    @include flex-jfc-ai-center;

    height: 32px;
    position: relative;
    line-height: 32px;
    white-space: nowrap;
    color: $button;
    border-radius: 3px;
    margin-right: -4px;

    .KebabDropDownMenu {
      @include no-touch-select;
      @include drop_down_menu;
      width: 384px;
      padding: 18px 8px;
      @include li-hover;
    }
  }

  .LogoWrap {
    @include flex-jfc-ai-center;

    height: 32px;
    border-radius: 3px;
    padding: 0 8px;

    &:hover {
      background-color: $hover_icon;
      transition: $transition_hover;
    }

    .Logo {
      @include no-touch-select;
      width: 75px;
      height: 16px;
      margin: 0;
      padding: 8px 0;
      opacity: 1;
      position: relative;
      width: 75px;
      filter: brightness(0) saturate(100%) invert(66%) sepia(20%) saturate(225%) hue-rotate(170deg) brightness(101%) contrast(85%) !important;
      // height: 16px;

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../public/img/logo_trello.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: 1;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../public/img/logo_trello_anim.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: 0;
      }
      &:hover:after {
        opacity: 0;
      }
      &:hover:before {
        opacity: 1;
      }
    }
  }

  .CenterMenu {
    display: flex;
    flex-basis: 100%;

    .CenterMenuWrap {
      display: flex;
      align-items: stretch;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      height: 100%;
      position: relative;

      .DropDownMenu {
        max-width: 573px;
        height: 32px;

        flex-shrink: 0;
        margin: 0 4px;

        display: flex;

        .MenuWorkspace {
          position: relative;
          margin-right: 4px;

          .WorkspaceDropDownMenu {
            @include no-touch-select;
            @include drop_down_menu;

            .LineSeparator {
              border-top: 1px solid $border_line;
              margin: 12px -12px 0 -12px;
            }

            .TitleText {
              font-size: 12px;
              font-weight: 600;
              color: $text;
              margin: 16px 8px 8px 8px;
              line-height: 16px;
            }

            ul {
              margin: 0;

              li {
                border-radius: 8px;
              }

              li.UseHover:hover {
                background-color: $hover_li;
                transition: $transition_hover;
              }
            }
          }
        }

        .MenuRecent {
          position: relative;

          .RecentDropDownMenu {
            @include no-touch-select;
            @include drop_down_menu;
            @include li-hover;
          }
        }

        .MenuFavourites {
          margin-right: 4px;
          position: relative;

          .FavouritesDropDownMenu {
            @include no-touch-select;
            @include drop_down_menu;
            @include li-hover;
          }
        }

        .MenuTemplates {
          margin-right: 4px;
          position: relative;

          .TemplatesDropDownMenu {
            @include no-touch-select;
            @include drop_down_menu;
            @include li-hover;

            padding: 12px;
          }
        }
      }

      .ButtonCreate {
        @include flex-jfc-ai-center;
        @include no-touch-select;
        margin: 0 4px;
        width: 80px;
        height: 32px;
        background-color: $active;
        border-radius: 3px;
        color: $button_color;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          cursor: pointer;
          background-color: #85B8FF;
        }
      }
    }
  }

  .RightMenu {
    display: flex;
    justify-content: flex-end;

    .blockSearch {
      position: relative;
      max-width: 100%;
      width: 200px;
      margin-right: 4px;
    }
    .blockNotification {
      @include flex-jfc-ai-center;
      width: 32px;
      height: 32px;
    }
  }
}

.Display {
  display: block;
}

.NoneDisplay {
  display: none;
}

.WorkspaceDropDownMenu::-webkit-scrollbar,
.RecentDropDownMenu::-webkit-scrollbar,
.FavouritesDropDownMenu::-webkit-scrollbar,
.TemplatesDropDownMenu::-webkit-scrollbar {
  width: 8px;
}

.WorkspaceDropDownMenu::-webkit-scrollbar-track,
.RecentDropDownMenu::-webkit-scrollbar-track,
.FavouritesDropDownMenu::-webkit-scrollbar-track,
.TemplatesDropDownMenu::-webkit-scrollbar-track {
  background-color: #323940;
  border-radius: 8px;
}

.WorkspaceDropDownMenu::-webkit-scrollbar-thumb,
.RecentDropDownMenu::-webkit-scrollbar-thumb,
.FavouritesDropDownMenu::-webkit-scrollbar-thumb,
.TemplatesDropDownMenu::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 8px #455159;
  border-radius: 8px;
}

.ButtonIcon {
  @include no-touch-select;
  @include flex-jfc-ai-center;

  height: 32px;
  line-height: 32px;
  margin-right: 4px;

  white-space: nowrap;
  border-radius: 3px;

  position: relative;
  font-style: 14px;
  font-weight: 500;
  line-height: 32px;

  &.ButtonIconActive {
    background-color: $background_hover;
  }

  &:hover {
    background-color: $hover_icon;
    cursor: pointer;
  }
}

.MenuButton {
  @include no-touch-select;
  @include flex-jfc-ai-center;

  height: 32px;
  line-height: 32px;
  margin-right: 4px;

  white-space: nowrap;
  border-radius: 3px;

  position: relative;
  font-style: 14px;
  font-weight: 500;
  line-height: 32px;

  &.MenuButtonActive {
    background-color: $background_hover;

    .MenuTextIconActive {
      color: $active;
    }
  }

  &:hover {
    background-color: $hover_li_2;
    cursor: pointer;
    transition: $transition_hover;
  }
}

.blockSearch {
  position: relative;
  max-width: 100%;
  width: 200px;
}
.none{
  display: none;
}
// стили для Loupe
.Loupe {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 8px;
  color: #8c9bab;
  // line-height: 0;
  display: inline-block;
  flex-shrink: 0;
}
.Loupe:focus {
  box-shadow: 0 0 0 2px #85b8ff;
  border-radius: 100%;
  outline: none;
}
// стили для Loupe
