.smallWindowWrap{
    background-color: #282e32;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #39424a, 0px 8px 12px #0304045C, 0px 0px 1px 1px #03040480;
    overflow: hidden;
    position: absolute;
    width: 304px;
    min-height: 30px;
    cursor: default;
    top: -30px;
    right: 200px;

    .itemHeader{
        align-items: center;
        display: grid;
        grid-template-columns: 32px 1fr 32px;
        padding: 4px 8px;
        position: relative;
        text-align: center;

        .itemHeaderTitle{
            box-sizing: border-box;
            color: #9fadbc;
            display: block;
            font-size: 14px;
            font-weight: 600;
            grid-column: 1 / span 3;
            grid-row: 1;
            height: 40px;
            letter-spacing: -.003em;
            line-height: 16px;
            line-height: 40px;
            margin: 0;
            overflow: hidden;
            padding: 0 32px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            z-index: 1;
        }
        .iconWrap{
            width: fit-content;
            height: fit-content;
            border-radius: 8px;
            color: #626f86;
            grid-column: 3;
            grid-row: 1;
            padding: 3px;
            z-index: 2;
            display: flex;
        }
    }
    .itemContent{
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 12px 12px;
        max-height: 300px;
        scrollbar-width: thin;
        scrollbar-color: #a6c5e229 #a1bdd914;

        input{
            background-color: #22272b;
            border: none;
            border-radius: 3px;
            box-shadow: inset 0 0 0 1px #738496;
            box-sizing: border-box;
            display: block;
            line-height: 20px;
            margin-bottom: 12px;
            outline: none;
            padding: 8px 12px;
        }
        .itemContentInput{
            margin: 4px 0 12px;
            width: 100%;
        }

        .itemContentCardMembers, 
        .itemContentDashboardMembers{
            

            .itemContentCardMembersTitle,
            .itemContentDashboardMembersTitle{
                display: flex;
                align-items: center;

                h4{
                    margin-top: 16px;
                    color: #9fadbc;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                }
            }

            .itemContentDashboardMember{
                // display: inline-block;
                // margin-right: 8px;
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li{
                        display: flex;
                        align-items: center;
                        // padding: 4px 8px 4px 4px;
                        height: 40px;
                        width: 100%;
                    }
                }

                .itemContentDashboardMemberInfo{
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .itemContentDashboardMemberName{
                        flex: 1;
                        text-align: start;
                    }
                }

                .itemContentDashboardMemberImg{
                    // display: flex;

                    div{
                    flex: 1;
                    margin-right: 8px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    }
                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        color: #d7d7d7;
                        border: 1.5px solid #282e33;
                        border-radius: 50%;
                        background-color: #2121ff;
                        background-size: cover;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }

    }
}

.itemContentDashboardMember{
    // display: inline-block;
    // margin-right: 8px;
    ul{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
            display: flex;
            align-items: center;
            // padding: 4px 8px 4px 4px;
            height: 40px;
            width: 100%;
        }
    }

    .itemContentDashboardMemberInfo{
        width: 100%;
        display: flex;
        align-items: center;

        .itemContentDashboardMemberName{
            flex: 1;
            text-align: start;
        }
    }

    .itemContentDashboardMemberImg{
        // display: flex;

        div{
        flex: 1;
        margin-right: 8px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            color: #d7d7d7;
            border: 1.5px solid #282e33;
            border-radius: 50%;
            background-color: #2121ff;
            background-size: cover;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.noResult{
    margin: 0;
    padding: 24px 6px;
    border-radius: 3px;
    background-color: #a1bdd914;
    color: #9fadbc;
    text-align: center;
    font-size: 14px;
}

// .iconWrap{
//     width: fit-content;
//     height: fit-content;
  
//     &:hover {
//       border: none;
//       background-color: #a6c5e229;
//       box-shadow: none;
//       color: #b6c2cf;
//       text-decoration: none;
//       border-radius: 8px;
//       display: flex;
//       justify-content: center;
//     }
// }

.cardMembersWindowGradient {
    background: linear-gradient(45deg, #22272b, #616161, #22272b);
    background-size: 200% 800%;
    animation: membersWindowGradient 2s ease infinite;
    border-radius: 3px;
    cursor: default;
}

@keyframes membersWindowGradient {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}