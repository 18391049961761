.cardActivity{

    .cardActivityWrap{
        clear: both;
        margin-bottom: 24px;
        position: relative;

        .cardActivityHeader{
            align-items: center;
            display: flex;
            justify-content: space-between;
            min-height: 32px;
            margin: 0 0 4px 40px;
            padding: 8px 0;
            position: relative;

            .cardActivityHeaderTitle{
                display: inline-block;
                margin: 0;
                min-height: 18px;
                min-width: 40px;
                width: auto;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                cursor: default;
            }
        }

        .cardActivityNewComment{
            display: flex;
            padding: 8px 0;
            position: relative;

            .cardActivityMemberAvatar{
                background-color: #454f59;
                color: #b6c2cf;
                border-radius: 50%;
                cursor: pointer;
                height: 32px;
                margin: 0 4px 4px 0;
                position: relative;

                .cardActivityMemberAvatarImg{
                    border-radius: 50%;
                    height: 32px;
                    object-fit: cover;
                    width: 32px;
                    background-color: #2c5683;
                    border: 1px solid #282e33;

                    &:hover {
                        opacity: .8;
                    }
                    &:active {
                        opacity: .7;
                    }
                }
                .cardActivityMemberAvatarSpan{border-radius: 50%;
                    border-radius: 50%;
                    height: 32px;
                    object-fit: fill;
                    width: 32px;
                    background-color: #2121ff;
                    border: 1px solid #282e33;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 500;
                    color: #d7d7d7;

                    &:hover {
                        opacity: .8;
                    }
                    &:active {
                        opacity: .7;
                    }
                }
            }

            .cardActivityNewCommentContent{
                width: 100%;
                padding-left: 6px;
            }

            .cardActivityMemberCommentAction{
                font-size: 14px;
            }

            .cardActivityNewCommentInput{
                box-shadow: 0px 1px 1px #091e4240, 0px 0px 1px #091e424f;
                border-radius: 8px;
                box-sizing: border-box;
                border: none;
                outline: none;
                color: #b6c2cf;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                transition-duration: 85ms;
                transition-timing-function: ease;
                padding: 8px 12px;
                background-color: #22272b;
                width: 100%;
                margin-bottom: 0;
                cursor: pointer;
            }

            .cardActivityNewCommentInputGradient {
                background: linear-gradient(45deg, #22272b, #616161, #22272b);
                background-size: 200% 800%;
                animation: newCommentGradient 2s ease infinite;
                cursor: default;
            }
            
            @keyframes newCommentGradient {
                0% {
                    background-position: 50% 0%;
                }
                50% {
                    background-position: 50% 100%;
                }
                100% {
                    background-position: 50% 0%;
                }
            }

            .cardActivityMemberName{
                font-weight: 700;
                font-size: 14px;
                margin-right: 6px;
                cursor: default;
            }

            .cardActivityMemberCommentDate{
                font-size: 11px;
                line-height: 14px;
                margin: 0 0 6px;
                min-width: 105px;
                color: #9fadbc;
                cursor: default;
            }
            .cardActivityCommentSending{
                font-size: 12px;
            
                .cardActivityCommentSendingImg{
                    background-image: url("../../../public/send_icon.svg");
                    background-size: 18px 18px;
                    height: 18px;
                    width: 18px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 3px;
                }
            }
        }
    }
}

.reactQuill{
    background-color: #1d2125;
    height: max-content;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #738496;

    &:focus-within {
        box-shadow: 0 0 0 2px #579dff;
    }
}

.cardEditorButtonWrap,
.cardDescriptionButtonWrap{
    margin-top: 8px;
}

.smallWindowWrap{
    background-color: #282e32;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #39424a, 0px 8px 12px #0304045C, 0px 0px 1px 1px #03040480;
    overflow: hidden;
    position: absolute;
    width: 304px;
    min-height: 30px;
    cursor: default;
    top: -30px;
    right: 100px;
    z-index: 1;

    .itemHeader{
        align-items: center;
        display: grid;
        grid-template-columns: 32px 1fr 32px;
        padding: 4px 8px;
        position: relative;
        text-align: center;
        
        .itemHeaderTitle{
            box-sizing: border-box;
            color: #9fadbc;
            display: block;
            font-size: 14px;
            font-weight: 600;
            grid-column: 1 / span 3;
            grid-row: 1;
            height: 40px;
            letter-spacing: -.003em;
            line-height: 16px;
            line-height: 40px;
            margin: 0;
            overflow: hidden;
            padding: 0 32px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            z-index: 1;
        }
        .iconWrap{
            border-radius: 8px;
            color: #626f86;
            grid-column: 3;
            grid-row: 1;
            padding: 3px;
            z-index: 2;
            display: flex;
        }
    }
    .delButtonWrap{
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 12px 12px;

        .delButtonWrapText{
            margin: 0 0 8px;
            font-size: 14px;
        }
    }
}