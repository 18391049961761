.react-datepicker__tab-loop{
    display: flex;
    justify-content: center;
}

.react-datepicker {
    background-color: transparent;
    color: #b6c2cf;
    border: none;
}

.react-datepicker__header {
    background-color: transparent;
    color: #b6c2cf !important;
    border-bottom: 1px solid #a1bdd914;
}

.react-datepicker__day-names{
    margin: 0;
}
.react-datepicker__current-month,
.react-datepicker__day,
.react-datepicker__day-name{
    color: #b6c2cf;
    font-weight: normal;
    margin: 2px 5px;
}
.react-datepicker__day:hover{
    background-color: rgba(166, 197, 226, 0.1607843137);
}
.react-datepicker__day--keyboard-selected{
    color: #ffffff;
    font-weight: 500;
    background-color: rgba(166, 197, 226, 0.1607843137);
}
.react-datepicker__day--disabled{
    color: #000;
}
.react-datepicker__month-container {
    margin-bottom: 8px;
}

.react-datepicker-wrapper {
    position: absolute;
    top: 350px;
}
.react-datepicker-popper {
    position: initial!important;
    transform: none !important;
}
.react-datepicker-popper > svg{
    display: none;    
}
.react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
}
.react-datepicker-time__input input{
    width: 90px;
    margin: 0;
    padding: 6px;

    box-sizing: border-box;
    border: none;
    outline: none;
    color: #b6c2cf;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition-property: background-color, border-color, box-shadow;
    transition-duration: 85ms;
    transition-timing-function: ease;
    border-radius: 3px;
    background-color: #22272b;
    box-shadow: inset 0 0 0 1px #738496;
}
.react-datepicker-time__input input::-webkit-calendar-picker-indicator {
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}
.react-datepicker-time__input input::-webkit-datetime-edit-hour-field,
.react-datepicker-time__input input::-webkit-datetime-edit-minute-field{
    color: #b6c2cf;
}

.react-datepicker__input-time-container {
    clear: both;
    width: fit-content;
    /* margin: 8px; */
    text-align: left;
    position: absolute;
    bottom: -3px;
    left: 110px;
}
.react-datepicker__children-container {
    display: flex;
}

.react-datepicker__today-button {
    background: #323940;
    border: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: normal;
    /* padding: 5px 0; */
    clear: left;
    border-radius: 3px;
    padding: 6px 12px;
    /* font-size: 14px; */
}
.react-datepicker__today-button:hover {
    border: 1px solid #aeaeae;
    background-color: rgba(166, 197, 226, 0.1607843137);
    box-shadow: none;
    color: #b6c2cf;
    text-decoration: none;
}
.react-datepicker__today-button:active {
    border: 1px solid #aeaeae;
    outline: 0;
    background-color: rgba(191, 219, 248, 0.2784313725);
    box-shadow: none;
    color: #b6c2cf;
}
/* .react-datepicker__month-dropdown{
    background-color: #22272b;
    color: #b6c2cf;
}
.react-datepicker__month-option:hover {
    background-color: rgba(166, 197, 226, 0.1607843137);
} */