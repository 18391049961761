.cardDescription{
    position: relative;
    margin-bottom: 24px;
    clear: both;

    .cardDescriptionHeader{
        display: flex;
        position: relative;
        align-items: center;
        min-height: 32px;
        margin: 0 0 4px 40px;
        padding: 8px 0;

        .cardDescriptionHeaderIcon{
            position: absolute;
            top: 9px;
            left: -36px;
            color: #9fadbc;
        }

        .cardDescriptionHeaderTitle{
            flex: 0 0 auto;
            margin: 0;
            font-weight: 600;
        }

        .cardDescriptionHeaderBtn{
            display: flex;
            flex: 1 1;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
        }
    }
}

.reactQuill{
    background-color: #1d2125;
    height: max-content;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #738496;

    &:focus-within {
        box-shadow: 0 0 0 2px #579dff;
    }
}

.cardEditorButtonWrap,
.cardDescriptionButtonWrap{
    margin-top: 8px;
}

.cardDescriptionStub{
    margin-left: 40px;
    border: none;
    background-color: #a1bdd914;
    box-shadow: none;
    font-weight: 500;
    display: block;
    min-height: 55px;
    padding: 8px 12px;
    border-radius: 3px;
    color: #b6c2cf;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        border: none;
        background-color: #a6c5e229;
        box-shadow: none;
        color: #b6c2cf;
        text-decoration: none;
    }
    &:active {
        border: none;
        outline: 0;
        background-color: #bfdbf847;
        box-shadow: none;
        color: #b6c2cf;
    }
}

.cardDescriptionGradient {
    background: linear-gradient(45deg, #22272b, #616161, #22272b);
    background-size: 200% 800%;
    animation: descriptionGradient 2s ease infinite;
    border-radius: 3px;
    cursor: default;
}
@keyframes descriptionGradient {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}