$background_1: #cc4223;
$background_2: #fea362;
$--ds-text-subtle: #9FADBC;
$--ds-text: #B6C2CF;

.Workspace {
  .WorkspaceWrap {
    display: block;
    width: 100%;

    .WorkspaceTitle {
      box-sizing: content-box;
      display: flex;
      position: relative;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 850px;
      margin: 0 auto;
      padding: 32px;

      .WorkspaceTitleContent {
        display: flex;
        align-items: center;

        .WorkspaceTitleIconLetter {
          margin-right: 10px;
          border-radius: 4px;
          background: linear-gradient($background_1, $background_2);
          position: relative;

          .ChangeLogo {
            position: absolute;
            z-index: 999;
            top: 110%;
            display: flex;
            justify-content: center;
            width: 304px;
            height: 100px;
            border: 0.3px solid #a6c5e229;
            border-radius: 8px;
            background-color: #282e33;
          }
        }

        .WorkspaceTitleName {
          display: flex;
          flex-direction: column;

          .Name {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: #b6c2cf;
          }

          .VisibilityStatus {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            padding: 0 6px 0 2px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }

      .WorkspaceTitleButton {
        flex-shrink: 0;
        background-color: #579dff;
        cursor: pointer;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        line-height: 20px;

        .TitleButtonIcons {
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          margin-right: 8px;
        }
      }
    }

    .WorkspaceLine {
      width: 93%;
      height: 1px;
      margin: auto;
      background-color: #a6c5e229;
      border: 0;
    }

    .WorkspaceContent {
      display: flex;
      flex-direction: column;
      padding: 32px;
      margin: 0 auto;
      max-width: 1266px;

      .WorkspaceContentTitle {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }

      .WorkspaceContentSortFilterSearch {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1250px;
        padding: 20px 0 8px;
        .SortFilter {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          & > div {
            margin-right: 4px;
          }
        }
        .Search {
          
          label {
            color: $--ds-text-subtle;
            display: block;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 4px;
            margin-top: 12px;
          }
          input {
            width: 250px;
            height: 36px;
            padding-left: 32px;
            border-radius: 3px;
            color: $--ds-text;
          }
          .blockSearch {
            position: relative;
            max-width: 100%;
            display: flex;
            align-items: center;
          }
          
          .Loupe { // стили для Loupe
            width: 16px;
            height: 16px;
            position: absolute;
            top: unset;;
            left: 8px;
            color: #8c9bab;
            // line-height: 0;
            display: inline-block;
            flex-shrink: 0;
          }
          .Loupe:focus {
            box-shadow: 0 0 0 2px #85b8ff;
            border-radius: 100%;
            outline: none;
          } // стили для Loupe
        }
      }

      .WorkspaceContentDashboards {
        max-width: 1266px;
        padding: 20px 0 0;

        .ListDashboards {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin: 0 -8px;

          & > li {
            box-sizing: border-box;
            cursor: pointer;
            padding: 4px 8px;
            position: relative;
            width: 25%;

            & > .DashboardWrap {
              position: relative;
              display: block;
              height: 96px;
              padding: 8px;
              background-color: #a1bdd914;
              border-radius: 3px;
              color:  #b6c2cf;
              text-decoration: none;

              .DashboardCardTitle {
                font-weight: 700;
              }

              .CreateNewDashboard {
                .HelpCreateNewDashboard {
                  position: absolute;
                  right: 5px;
                  bottom: 5px;

                  .HelpMessage {
                    display: none;
                    position: absolute;
                    left: 0px;
                    top: 27px;
                    z-index: 9999;
                    width: 242px;
                    padding: 12px;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: justify;
                    border-radius: 8px;
                    border: 0.4px solid #a6c5e229;
                    background-color: #282e33;
                    & > p {
                      color: #b6c2cf;
                      margin: 0;
                      padding: 0;
                    }
                  }
                  &:hover .HelpMessage {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ActiveFrame {
  outline: #579dff auto 2px;
}

.ShowBlock {
  display: block;
}

.HideBlock {
  display: none;
}
