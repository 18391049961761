$text: #172b4d;
$text_2: #b6c2cf;
$text_title: #8c9bab;
$background: #091e420f;
$background_1: #cc4223;
$background_2: #fea362;
$background_2: #fea362;


.CardDropdownMenuIcon {
  display: flex;
  border-radius: 8px;
  font-weight: 700;
  // height: 32px;
  height: auto;
  padding: 4px 8px 4px 4px;
  position: relative;
  text-decoration: none;

  color: $text_2;
  cursor: pointer;

  &:hover .CardIcon {
    visibility: visible;
    transform: scale(1.1);
  }

  .Image {
    display: inline-block;
    flex: 0 0 auto;
    background-size: cover;
    height: 32px;
    width: 40px;
    position: relative;
    opacity: 1;

    .ImageBackground {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .CardText {
    display: flex;
    flex-direction: column;

    position: relative;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .CardTheme {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 14px;
      color: $text_2;
      line-height: 18px;
      font-weight: 500;
    }

    .CardTitle {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      color: $text_title;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .CardIcon {
    visibility: hidden;
    padding: 0;
    margin-bottom: 0;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);

    &.IconActive {
      visibility: visible;
    }

    &:hover {
      transform: scale(1);
    }
  }
}
