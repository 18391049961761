.TemplatesDropDownMenu_Wrap {
  .TitleText {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    // margin-left: -5px;
  }
  ul {
    li {
      margin-top: 3px;
      // margin-left: -5px;
    }
  }
}
