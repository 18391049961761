.wrap{
    position: relative;
    width: 768px;
    min-height: 400px;
    // margin: 0 auto;
    margin: 40px auto;
    background: transparent;
    background-color: #323940;
    border-radius: 8px;
    display: grid;
    cursor: default;
}
.dragging{
    border: 2px solid #9fadbc;
    background-color: #586773;
}
.header {
    min-height: 32px;
    padding: 8px 52px 8px 56px;
    position: relative;
    grid-column: 1 / 5;
    grid-row: 1;
    z-index: 1;

    .headerIcon{
        left: 16px;
        position: absolute;
        top: 22px;
        color: #9fadbc;
        height: 32px;
        line-height: 32px;
        width: 32px;
        font-size: 24px;

        display: inline-block;
        font-family: trellicons;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        vertical-align: bottom;
    }

    .headerTitle{
        padding: 12px 0 0;
        width: 95%;

        h2{
            margin-right: 4px;
            cursor: pointer;
            font-weight: 600;

        }
        
        textarea{
            resize: vertical;
            width: 100%;
            background: #0000;
            border-radius: 4px;
            box-shadow: none;
            font-size: 20px;
            font-weight: 600;
            height: 32px;
            line-height: 24px;
            margin: -6px -10px;
            min-height: 24px;
            padding: 6px 10px;
            resize: none;
            background-color: #22272b;
            border: none;
            border-radius: 3px;
            box-shadow: inset 0 0 0 1px #738496;
            box-sizing: border-box;
            display: block;
            line-height: 20px;
            margin-bottom: 12px;
            outline: none;
            padding: 8px 12px;
            transition-duration: 85ms;
            transition-property: background-color, border-color, box-shadow;
            transition-timing-function: ease;

            &:focus {
                background-color: #22272b;
                box-shadow: inset 0 0 0 2px #85b8ff;
            }
        }
    }
    .columnTitle{
        display: flex;
        align-items: center;
        cursor: default;
        width: fit-content;
        margin: 4px 8px 4px 2px;
        color: #9fadbc;
        font-size: 14px;

        .columnTitleName{
            cursor: default;
            margin: 4px 8px 4px 2px;
            color: #9fadbc;
        }
    }
}
.mainCol {
    margin: 0;
    min-height: 24px;
    padding: 0 8px 8px 16px;
    position: relative;
    width: 550px;
    grid-column: 1 / 4;
    grid-row: 2;
    z-index: 1;

    .cardDetails{
        margin-top: 8px;
        margin-left: 40px;
        display: flex;
        flex-wrap: wrap;

        .cardDetailItem{
            display: block;
            margin: 0 8px 8px 0;
            max-width: 100%;
        }

        .cardDetailNotifications{
            height: 64px;
            
            .cardDetailsTitle{
                color: #9fadbc;
                display: block;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                line-height: 20px;
                margin: 0 8px 4px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .membersList{
                display: flex;
                
                .memberMenu{
                    cursor: pointer;
                    margin: 0 4px 4px 0;
                    border-radius: 50%;
                    height: 32px;
                    position: relative;

                    &:hover {
                        background-color: #454f59;
                        color: #b6c2cf;
                    }
                    &:active {
                        background-color: #596773;
                        color: #b6c2cf;
                    }
                }
                .memberAvatar{
                    border-radius: 50%;
                    height: 32px;
                    object-fit: cover;
                    width: 32px;
                    background-color: #2c5683;
                    border: 1px solid #282e33;

                    &:hover {
                        opacity: .8;
                    }
                    &:active {
                        opacity: .7;
                    }
                }
                .memberAvatarSpan{border-radius: 50%;
                    border-radius: 50%;
                    height: 32px;
                    object-fit: fill;
                    width: 32px;
                    background-color: #2121ff;
                    border: 1px solid #282e33;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 500;
                    color: #d7d7d7;

                    &:hover {
                        opacity: .8;
                    }
                    &:active {
                        opacity: .7;
                    }
                }
            }
            .labelsList{
                display: flex;
                flex-wrap: wrap;
                gap: 4px;

                .labelElement{
                    display: inline-block;
                    position: relative;
                    box-sizing: border-box;
                    min-width: 48px;
                    max-width: 100%;
                    height: 32px;
                    margin-bottom: 0;
                    padding: 0 12px;
                    overflow: hidden;
                    border-radius: 3px;
                    color: #baf3db;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 32px;
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:hover {
                        background-color: #164b35;
                        cursor: pointer;
                    }
                    &:focus {
                        outline: -webkit-focus-ring-color auto 5px;
                        outline: #85b8ff auto 2px;
                    }
                }
            }


            .cardDetailsInfo{
                overflow: hidden;
            }

            .simbolSubscribed{
                display: block;
                background-color: #8c9bab;
                border-radius: 3px;
                font-weight: 400;
                width: 24px;
                height: 24px;
                line-height: 20px;
                margin: 0;
                padding: 8px 7px;
                position: absolute;
                right: 4px;
                text-align: center;
                top: 4px;
                color: #1d2125 !important;
                font-size: 16px;
            }
        }
    }
}

.reactQuill{
    background-color: #1d2125;
    height: max-content;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #738496;

    &:focus-within {
        box-shadow: 0 0 0 2px #579dff;
    }
}

.cardEditorButtonWrap,
.cardDescriptionButtonWrap{
    margin-top: 8px;
}

.iconWrap{
    width: fit-content;
    height: fit-content;
  
    &:hover {
      border: none;
      background-color: #a6c5e229;
      box-shadow: none;
      color: #b6c2cf;
      text-decoration: none;
      border-radius: 8px;
      display: flex;
      justify-content: center;
    }
}

.wimdowModalGradient {
    background: linear-gradient(45deg, #22272b, #616161, #22272b);
    background-size: 200% 800%;
    animation: wimdowModalGradient 2s ease infinite;
    border-radius: 3px;
    cursor: default;
}

@keyframes wimdowModalGradient {
    0% {
        background-position: 50% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 50% 0%;
    }
}