.ql-toolbar{
  border: none!important;
  border-radius: 3px 3px 0 0;
  background-color: #1d2125;
}
.ql-container {
  background-color: #22272b;
  /* min-height: 175px; */
  border: none!important;
  border-radius: 0 0 3px 3px;
}
.ql-snow .ql-picker {
  color: #b6c2cf!important;
}
.ql-editor{
  color: #b6c2cf;
  /* min-height: 175px; */
  cursor: pointer;
  box-shadow: inset 0 2px 0px 0 #2c333a;
}
.ql-editor.ql-blank::before{
  color: #8c9baa;
}
.ql-stroke{
  stroke: #b6c2cf!important;
}
.ql-fill{
  fill: #b6c2cf!important;
}
.ql-transparent {
  opacity: 1!important;
}